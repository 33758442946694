@tailwind base;
@tailwind components;
@tailwind utilities;
body{
  font-family: 'Montserrat', sans-serif;
  @apply bg-bg_gray;
}
.sticky{
  position: sticky;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}